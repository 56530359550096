import React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO, Hero, ContactSection } from '../components';
import { useGraphQL } from '../hooks';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <HeroSection />
      <ContactSection />
    </Layout>
  );
}

function HeroSection() {
  const { successNotFoundHero } = useGraphQL();

  return (
    <Hero image={successNotFoundHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-white heading-1">404: Page Not Found</h1>
        <p className="text-lg text-white uppercase">
          The page you were looking for could not be found
        </p>
        <Link to="/" className="text-white button">
          Return Home
        </Link>
      </div>
    </Hero>
  );
}

export default NotFoundPage;
